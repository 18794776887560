<template>
  <!-- 人车关系 -->
  <div class="driverRelation">
    <div class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">司机姓名：</div>
        <div class="col-2">
          <el-input v-model="dataForm.driverName" clearable></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">所属企业：</div>
        <div class="col-2">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valueAddName"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">机动司机：</div>
        <div class="col-2">
          <el-select
            v-model="dataForm.isFlexible"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in isDriverList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">车牌号：</div>
        <div class="col-2">
          <el-select
            v-model="dataForm.licensePlateNumber"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in vehicleList"
              :key="item.id"
              :label="item.licensePlateNumber"
              :value="item.licensePlateNumber"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryFun"
          >查询</el-button
        >
        <el-button
          v-if="listFind('新增')"
          type="primary"
          size="small"
          @click="addFun"
          >添加</el-button
        >
      </div>
    </div>
    <OperAtion
      v-if="operAtionShow"
      :popup-num="2"
      :header-text="headerText"
      :oper-ation-data="operAtionData"
      @confirmFun="confirmFun"
      @closePopupFun="closePopupFun"
    />
    <Table
      :table-data="tableData"
      operation-width="110"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="listFind('编辑')"
            type="text"
            size="small"
            sort="primary"
            @click="editFun(scope.scopeRow)"
            >编辑</el-button
          >
          <el-button
            v-if="listFind('删除')"
            type="text"
            size="small"
            sort="primary"
            @click="deleteTableRow(scope.scopeRow)"
            >删除</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="dataForm.pageSize"
        :current-page="dataForm.currentPage"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  getCompanyTree,
  driverIntercityPageAPI,
  getVehicleListAllAPI,
  driverIntercityAddAPI,
  driverIntercityDeleteAPI,
  driverIntercityUpdateAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      operAtionData: {},
      headerText: "添加",
      tableData: [],
      titleName: [
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "司机类型",
          props: "driveType",
          SpecialJudgment: (res) => {
            let text = "";
            res.map((item) => {
              if (item === 0) {
                text += "定制客运司机、";
              } else if (item === 1) {
                text += "包车、";
              } else if (item === 2) {
                text += "租车、";
              } else if (item === 3) {
                text += "村通客运、";
              }
            });
            return text.substr(0, text.length - 1);
          },
        },
        {
          title: "所属企业",
          props: "companyName",
        },
        {
          title: "是否机动司机",
          props: "isFlexible",
          SpecialJudgment: (res) => {
            return res == 0 ? "否" : res == 1 ? "是" : "";
          },
        },
        {
          title: "绑定车辆",
          props: "vehicleName",
        },
      ],
      operAtionShow: false,
      isDriverList: [
        { value: 0, label: "否" },
        { value: 1, label: "是" },
      ],
      companyList: [],
      valueAddName: null,
      valueAdd: null,
      total: 0,
      dataForm: {
        currentPage: 1,
        pageSize: 10,
        driverName: "",
        companyIds: "",
        isFlexible: "",
        licensePlateNumber: "",
      },
      vehicleList: undefined,
      peopleCarid: null,
    };
  },
  created() {
    this.getCompanyList();
  },
  mounted() {
    // 获取查看所有车辆列表
    getVehicleListAllAPI().then((res) => {
      if (res.code === "SUCCESS") {
        this.vehicleList = res.data;
      }
    });
    this.renderData();
  },
  methods: {
    // 当前页码改变
    onCurrentChange(page) {
      this.dataForm.currentPage = page;
      this.renderData();
    },
    // 点击编辑按钮
    editFun(val) {
      this.peopleCarid = val.id;
      this.operAtionData = val;
      this.headerText = "编辑";
      this.operAtionShow = true;
    },
    // 点击删除按钮
    deleteTableRow(val) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将删除"),
          h("span", null, "，是否删除？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          driverIntercityDeleteAPI({ id: val.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.dataForm.currentPage = 1;
              this.renderData();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    // 点击查看按钮
    queryFun() {
      this.dataForm.currentPage = 1;
      this.renderData();
    },
    // 确定按钮回调
    confirmFun(obj, bool, text) {
      if (text == "添加") {
        if (
          (obj.driverIds || obj.driverIds === 0) &&
          (bool == true ? true : obj.vehicleIds.length > 0 ? true : false) &&
          (obj.isFlexible || obj.isFlexible === 0)
        ) {
          // 新增人车关系数据接口
          driverIntercityAddAPI({
            driverId: obj.driverIds.value,
            isFlexible: obj.isFlexible,
            vehicleIdList: obj.vehicleIds,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.dataForm.currentPage = 1;
              this.dataForm.pageSize = 10;
              this.renderData();
              this.closePopupFun();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error("请填写必填项");
        }
      } else if (text == "编辑") {
        driverIntercityUpdateAPI({
          id: this.peopleCarid,
          driverId: obj.driverIds.value,
          isFlexible: obj.isFlexible,
          vehicleIdList: obj.vehicleIds,
        }).then((res) => {
          if (res.code == "SUCCESS") {
            this.dataForm.currentPage = 1;
            this.dataForm.pageSize = 10;
            this.renderData();
            this.closePopupFun();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 表格数据渲染
    renderData() {
      driverIntercityPageAPI(this.dataForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      });
    },
    // 企业回显
    getAddGroupId(v) {
      if (v == null) {
        this.dataForm.companyIds = [];
      } else {
        this.dataForm.companyIds = [v];
      }
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 操作弹窗关闭按钮callback
    closePopupFun(val) {
      this.operAtionShow = val;
    },
    addFun() {
      this.headerText = "添加";
      this.operAtionShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.driverRelation {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: "";
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 13px);
      height: 32px;
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;
      .col-1 {
        width: 84px;
        display: inline-block;
      }
      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
